/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import "./custom.css"
import Navbar from "./Navbar/Navbar"
import CookieConsent from 'react-cookie-consent';
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { isLoggedIn, logout } from '../services/auth'
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
		
	
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar />
      <div
        style={{
          //margin: `0 auto`,
          //maxWidth: 960,
          //padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
	<footer id="fh5co-footer" role="contentinfo">
		<div class="container">
			<div class="row row-pb-md">
				<div class="col-md-6 fh5co-widget">
					<h3>STEUNVERKOOP.</h3>
					<p>Steunverkoop is een initatief van Chateau BoHo om scholen en andere organisaties te helpen de sponsordoelen te realiseren en het administratief proces errond te ontzorgen.</p>
				</div>
				
				<div class="col-md-4 col-sm-6 col-xs-8 col-md-push-1">
					<ul class="fh5co-footer-links">
						<li><a href="https://chateauboho.be/">Chateau BoHo Webshop</a></li>
					</ul>
					<ul class="fh5co-footer-links">
						<li><a href="/termsandconditions/index.html">Terms and Conditions</a></li>
					</ul>
					<ul class="fh5co-footer-links">
						<li><a href="/privacypolicy/index.html">Our Privacy Policy</a></li>
					</ul>
				</div>
			</div>
			

			<div class="row copyright">
				<div class="col-md-12 text-center">
					<p>
						<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-wallet"></i>
						</span>

						</div>
					</p>
					<p>
						{isLoggedIn() ? (
					      <span
					        onClick={() =>
					          Auth.signOut()
					          .then(logout(() => navigate('/app/login')))
					          .catch(err => console.log('error:', err))
					        }
					        onKeyDown={() =>
					          Auth.signOut()
					          .then(logout(() => navigate('/app/login')))
					          .catch(err => console.log('error:', err))
					        }
					        role="button"
					        tabIndex="0"
					      >
					      <button className="mt-8 ml-8 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
					      UITLOGGEN
					      </button>
					      </span>
					    ) : (
					      <span>
					        <Link to="/app/login">
					        <button className="mt-8 ml-8 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">INLOGGEN</button></Link>
					      </span>
					    )}
					</p>
					{isLoggedIn() ? (
					      <span>
					      <Link to="/app/home">
					        <button className="mt-8 ml-8 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">DASHBOARD</button></Link>
					      </span>
					    ) : (
					     <p></p>
					    )}
					<p>
					</p>
					
				</div>
			</div>
			
			<div class="row copyright">
				<div class="col-md-12 text-center">
						<p>
						<small class="block">
							Dit is een <a href="https://chateauboho.be/">CHATEAU BoHo </a>Initiatief. 
						</small>
					</p>
					<p>
						<small class="block">
							Adres : Schoenstraat 46-48, 2140 BOrgerHOut-Antwerpen. Ondernemingsnummer : 0858304005
						</small>
					</p>
					<p>
					<small class="block"> © {new Date().getFullYear()}, Built by{` `} <a href="https://www.mighty-real.com">Mighty Real ICT & Media Solutions</a></small>
					</p>
				</div>
			</div>

		</div>
		
		<CookieConsent 
		enableDeclineButton 
		debug={false}
		flipButtons
		style={{ background: "#d1c286" }}		
		location="bottom"
        buttonText="Accepteer"
        buttonStyle=""
        buttonId=""
        buttonClasses="btn btn-warning"
        declineButtonText="Enkel functioneel"
        declineButtonClasses="btn btn-link btn-sm"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses=""
        cookieName="gatsby-gdpr-google-analytics">
        Deze website gebruikt cookies. De cookies zijn nodig voor het functioneren van de site. Wij respecteren uw Privacy. Voor meer info : check onze Privacy pagina. 
		</CookieConsent>
		
		
	</footer>
     
      </div>
     
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
